function addFirstLastClass(){
	this.$owlItems.removeClass('first last');
	this.$owlItems.eq(this.currentItem).addClass('first');
	this.$owlItems.eq(this.currentItem + (this.owl.visibleItems.length - 1)).addClass('last');
}

$(document).ready(function () {
	// tm_top link
	$('#links_block_top .title_block').click(function(){
		$('#links_block_top .block_content').slideToggle("slow");
		$('#links_block_top .title_block').toggleClass('active');
	});

	// cp_vertical desk
	$('.cpvm-contener .block-title').click(function(){
		$('#sidevertical_menu #top-menu').slideToggle("slow");
		$('.cpvm-contener .block-title').toggleClass('active');
	});

	//moveProductAttributes();
	prestashop.on('updatedProduct',function(event){
		//moveProductAttributes();
		qtyProgressBar();
	});

});

$(document).ready(function(){
	qtyProgressBar();
});
function qtyProgressBar(){
	$('.product-information, .product-description').each(function(){
		var $desc = jQuery(this).find('.progress');
		var $qty = jQuery(this).find('.quantity');
		var $pbar = jQuery(this).find('.progress-bar');
		var $progress = $desc;
		var $progressBar = $pbar;
		var $quantity = $qty.html();
		var currentWidth = parseInt($progressBar.css('width'));
		var allowedWidth = parseInt($progress.css('width'));
		var addedWidth = currentWidth + parseInt($quantity);
		if (addedWidth > allowedWidth) {
			addedWidth = allowedWidth;
		}
		var progress = (addedWidth / allowedWidth) * 100;
		$progressBar.animate({width: progress + '%' }, 100);
	});
	$('.product-information, .product-description').each(function(){
		if ($(".countdown-row").length > 0){
			$(".qtyprogress").css("display", "block");
		}
	});
}

function moveProductAttributes() {
	let $src = $('.js-product-attributes-source'),
		$dest = $('.js-product-attributes-destination'),
		$src2 = $('.js-product-availability-source'),
		$dest2 = $('.js-product-availability-destination');
	$dest.empty();
	if ($src.length) {
		$dest.html($src.html());
	}
	$dest2.empty();
	if ($src2.length) {
		$dest2.html($src2.html());
	}
}

$(document).on('click','.icon_menu, .carthome, .cart_custom ',function(){
	$('body').addClass('menu_shown');
	$('.overlay').addClass('overlay_active');
});
$(document).on('click','.close-icon, .overlay',function(){
	$('body').removeClass('menu_shown');
	$('.overlay').removeClass('overlay_active');
	$("#cp_sidevertical_menu_top").removeClass("slide");
});
$(document).on('click','.cart_block .remove-from-cart .material-icons',function(){
	$('.overlay').addClass('overlay_active');
});


function additionalCarousel(sliderId){
	/*======  curosol For Additional ==== */
	var tmadditional = $(sliderId);
	tmadditional.owlCarousel({
		items : 4, //10 items above 1000px browser width
		itemsDesktop : [1199,4],
		itemsDesktopSmall : [991,4],
		itemsTablet: [480,4],
		itemsMobile : [320,4]
	});
	// Custom Navigation Events
	$(".additional_next").click(function(){
		tmadditional.trigger('owl.next');
	})
	$(".additional_prev").click(function(){
		tmadditional.trigger('owl.prev');
	});
}

$(document).ready(function(){

	bindGrid();

	additionalCarousel("#main #additional-carousel");

	$('.menu-icon').on('click', function () {
		$('#cp_sidevertical_menu_top').toggleClass('slide');
		$('.menu-icon').toggleClass('active');
		$('body').toggleClass('active');
		$('#page').toggleClass('active');
		$('.overlay').addClass('overlay_active');
	});

	$(document).on('click','.menu-close,.overlay',function(){
		$('.overlay').removeClass('overlay_active');
		$('body').removeClass('active');
		$('#page').removeClass('active');
	});


	$(document).on('click','#search_filter_toggler.filter-mobile',function(){
		$('.filter-mobile').addClass('active');
		$('body').addClass('filter-menu');
	});
	$(document).on('click','#search_filters .text-uppercase,#search_filters',function(){
		$('body').removeClass('filter-menu');
		$('.filter-mobile').removeClass('active');
		$('#page').removeClass('active');
	});
	// ---------------- start more menu setting ----------------------
	if($(document).width() >= 1200 && $(document).width() >= 1449 ){
		var max_elem = 7;
	}
	else{
		var max_elem = 5;
	}
	var items = $('.header-top-main #_top_main_menu.menu ul#top-menu > li');
	var surplus = items.slice(max_elem, items.length);

	surplus.wrapAll('<li class="category more_menu" id="more_menu"><div id="top_moremenu" class="popover sub-menu js-sub-menu collapse"><ul class="top-menu more_sub_menu">');

	$('.header-top-main.bg_main #_top_main_menu.menu ul#top-menu .more_menu').prepend('<a href="#" class="dropdown-item" data-depth="0"><span class="pull-xs-right hidden-xs-up"><span data-target="#top_moremenu" data-toggle="collapse" class="navbar-toggler collapse-icons"><i class="material-icons add">&#xE313;</i><i class="material-icons remove">&#xE316;</i></span></span></span>'+ moreCategoriesText +'</a>');

	$('.header-top-main.bg_main #_top_main_menu.menu ul#top-menu .more_menu').mouseover(function(){
		$(this).children('div').css('display', 'block');
	})
		.mouseout(function(){
			$(this).children('div').css('display', 'none');
		});
	// ---------------- end more menu setting ----------------------


	// ---------------- start more menu setting ----------------------
	if ($(document).width() >= 992 && $(document).width() <= 1199){
		var max_elem = 7;
	}
	else if($(document).width() >= 1200 && $(document).width() <= 1450 ){
		var max_elem = 10;
	}
	else{
		var max_elem = 11;
	}

	var itemsleft = $('#left-column #cp_vertical_menu_top .menu ul#top-menu > li');
	if ( itemsleft.length > max_elem ) {
		$(' #left-column #cp_vertical_menu_top .menu ul#top-menu').append('<li><div class="more-wrap"><span class="more-view">'+ moreCategoriesText +'<i class="material-icons add">&#xE313;</i></span></div></li>');
	}

	$('#left-column #cp_vertical_menu_top .menu ul#top-menu .more-wrap ').click(function() {
		if ($(this).hasClass('active')) {
			itemsleft.each(function(i) {
				if ( i >= max_elem ) {
					$(this).slideUp(200);
				}
			});
			$(this).removeClass('active');
			$('.more-wrap').html('<span class="more-view">'+ moreCategoriesText +'<i class="material-icons add">&#xE313;</i></span>');
		} else {
			itemsleft.each(function(i) {
				if ( i >= max_elem  ) {
					$(this).slideDown(200);
				}
			});
			$(this).addClass('active');
			$('.more-wrap').html('<span class="more-view">'+ lessCategoriesText +'<i class="material-icons remove">&#xE316;</i></span>');
		}
	});

	itemsleft.each(function(i) {
		if ( i >= max_elem ) {
			$(this).css('display', 'none');
		}
	});
});

// Add/Remove acttive class on menu active in responsive
$('#menu-icon').on('click', function() {
	$(this).toggleClass('active');
});

$('#search_filter_toggler').on('click', function() {
	$(this).toggleClass('active');
});

// Loading image before flex slider load
$(window).load(function() {
	$(".loadingdiv").removeClass("spinner");
});

// Flex slider load
$(window).load(function() {
	if($('.flexslider').length > 0){
		$('.flexslider').flexslider({
			slideshowSpeed: $('.flexslider').data('interval'),
			pauseOnHover: $('.flexslider').data('pause'),
			animation: "fade"
		});
	}
});

// Scroll page bottom to top
$(window).scroll(function() {
	if ($(this).scrollTop() > 500) {
		$('.top_button').fadeIn(500);
	} else {
		$('.top_button').fadeOut(500);
	}
});
$('.top_button').click(function(event) {
	event.preventDefault();
	$('html, body').animate({scrollTop: 0}, 800);
});


/*======  Carousel Slider For categorylist ==== */

var tmcat = $("#cpcategorylist-carousel");
tmcat.owlCarousel({
	items : 8, //10 items above 1000px browser width
	itemsDesktop : [1449,7],
	itemsDesktopSmall : [1199,5],
	itemsTablet: [991,4],
	itemsMobile : [767,2],
	afterAction: addFirstLastClass,
});
// Custom Navigation Events
$(".cat_next").click(function(){
	tmcat.trigger('owl.next');
})
$(".cat_prev").click(function(){
	tmcat.trigger('owl.prev');
});


/*======  curosol For Manufacture ==== */
var tmbrand = $("#brand-carousel");
tmbrand.owlCarousel({
	items : 7, //10 items above 1000px browser width
	itemsDesktop : [1199,7],
	itemsDesktopSmall : [991,6],
	itemsTablet: [767,4],
	itemsMobile : [480,3],
	afterAction: addFirstLastClass,
});
// Custom Navigation Events
$(".brand_next").click(function(){
	tmbrand.trigger('owl.next');
})
$(".brand_prev").click(function(){
	tmbrand.trigger('owl.prev');
});

/*======  Carousel Slider For  New Product ==== */
var tmnewproduct = $("#newproduct-carousel");
tmnewproduct.owlCarousel({
	items : 7, //10 items above 1000px browser width
	itemsDesktop : [1449,6],
	itemsDesktopSmall : [1199,4],
	itemsTablet: [991,3],
	itemsMobile : [767,2],
	afterAction: addFirstLastClass,
});

// Custom Navigation Events
$(".newproduct_next").click(function(){
	tmnewproduct.trigger('owl.next');
})
$(".newproduct_prev").click(function(){
	tmnewproduct.trigger('owl.prev');
});

/*======  Carousel Slider For Special Product ==== */
var tmspecial = $("#special-carousel");
tmspecial.owlCarousel({
	items : 3, //10 items above 1000px browser width
	itemsDesktop : [1199,2],
	itemsDesktopSmall : [991,2],
	itemsTablet: [767,1],
	itemsMobile : [480,1],
	afterAction: function(el){
		this
			.$owlItems
			.removeClass('active')
		this
			.$owlItems
			.eq(this.currentItem )
			.addClass('active')
	}
});

// Custom Navigation Events
$(".special_next").click(function(){
	tmspecial.trigger('owl.next');
})
$(".special_prev").click(function(){
	tmspecial.trigger('owl.prev');
});


/*======  Carousel Slider For  bestseller ==== */
var tmbestseller = $("#bestseller-carousel");
tmbestseller.owlCarousel({
	items : 7, //10 items above 1000px browser width
	itemsDesktop : [1449,6],
	itemsDesktopSmall : [1199,4],
	itemsTablet: [991,3],
	itemsMobile : [767,2],
	afterAction: addFirstLastClass,
});

// Custom Navigation Events
$(".bestseller_prev").click(function(){
	tmbestseller.trigger('owl.next');
})
$(".bestseller_next").click(function(){
	tmbestseller.trigger('owl.prev');
});

/*======  Carousel Slider For Accessories Product ==== */
var tmaccessories = $("#accessories-carousel");
tmaccessories.owlCarousel({
	items : 7, //10 items above 1000px browser width
	itemsDesktop : [1449,6],
	itemsDesktopSmall : [1199,4],
	itemsTablet: [991,3],
	itemsMobile : [767,2],
	afterAction: addFirstLastClass
});
// Custom Navigation Events
$(".accessories_next").click(function(){
	tmaccessories.trigger('owl.next');
})
$(".accessories_prev").click(function(){
	tmaccessories.trigger('owl.prev');
});

/*======  Carousel Slider For Category Product ==== */
var tmproductscategory = $("#productscategory-carousel");
tmproductscategory.owlCarousel({
	items : 7, //10 items above 1000px browser width
	itemsDesktop : [1449,6],
	itemsDesktopSmall : [1199,4],
	itemsTablet: [991,3],
	itemsMobile : [767,2],
	afterAction: addFirstLastClass
});
// Custom Navigation Events
$(".productscategory_next").click(function(){
	tmproductscategory.trigger('owl.next');
})
$(".productscategory_prev").click(function(){
	tmproductscategory.trigger('owl.prev');
});

/*======  Carousel Slider For Feature Product ==== */
var tmfeature = $("#feature-carousel");
tmfeature.owlCarousel({
	items : 7, //10 items above 1000px browser width
	itemsDesktop : [1449,6],
	itemsDesktopSmall : [1199,4],
	itemsTablet: [991,3],
	itemsMobile : [767,2],
	afterAction: addFirstLastClass
});
// Custom Navigation Events
$(".feature_next").click(function(){
	tmfeature.trigger('owl.next');
})
$(".feature_prev").click(function(){
	tmfeature.trigger('owl.prev');
});

/*======  Carousel Slider For Blog ==== */
var tmblog = $("#blog-carousel");
tmblog.owlCarousel({
	items : 4, //10 items above 1000px browser width
	itemsDesktop : [1199,3],
	itemsDesktopSmall : [991,3],
	itemsTablet: [767,2],
	itemsMobile : [480,1]
});
// Custom Navigation Events
$(".blog_next").click(function(){
	tmblog.trigger('owl.next');
})
$(".blog_prev").click(function(){
	tmblog.trigger('owl.prev');
});

/*======  Carousel Slider For Crosssell Product ==== */
var tmcrosssell = $("#crosssell-carousel");
tmcrosssell.owlCarousel({
	items : 7, //10 items above 1000px browser width
	itemsDesktop : [1449,6],
	itemsDesktopSmall : [1199,4],
	itemsTablet: [767,2],
	itemsMobile : [480,2]
});
// Custom Navigation Events
$(".crosssell_next").click(function(){
	tmcrosssell.trigger('owl.next');
})
$(".crosssell_prev").click(function(){
	tmcrosssell.trigger('owl.prev');
});

/*======  Carousel Slider For For Tesimonial ==== */

var tmtesti = $("#cptestimonial");
tmtesti.owlCarousel({
	autoPlay: false,
	items: 4, //10 items above 1000px browser width
	itemsDesktop: [1449, 3],
	itemsDesktopSmall: [991, 2],
	itemsTablet: [767, 2],
	itemsMobile: [480, 1],
	afterAction: addFirstLastClass
});
// Custom Navigation Events
$(".cptesti_next").click(function () {
	tmtesti.trigger("owl.next");
});
$(".cptesti_prev").click(function () {
	tmtesti.trigger("owl.prev");
});

function bindGrid()
{
	var view = $.totalStorage("display");

	if (view && view != 'grid')
		display(view);
	else
		$('.display').find('li#grid').addClass('selected');

	$(document).on('click', '#grid', function(e){
		e.preventDefault();
		display('grid');
	});


	$(document).on('click', '#list', function(e){
		e.preventDefault();
		display('list');
	});
}

function display(view)
{
	if (view == 'list')
	{
		$('#products div.product_list').removeClass('grid').addClass('list');
		$('#products .product_list > article').removeClass('col-xs-12 col-sm-6 col-md-6 col-lg-3').addClass('col-xs-12');

		$('#products .product_list > article .thumbnail-container').addClass('col-xs-4 col-xs-5 col-md-3');
		$('#products .product_list > article .product-description ').addClass('center-block col-xs-4 col-xs-7 col-md-9');


		$('.display').find('li#list').addClass('selected');
		$('.display').find('li#grid').removeAttr('class');
		$.totalStorage('display', 'list');
	}
	else
	{
		$('#products div.product_list').removeClass('list').addClass('grid');
		$('#products .product_list > article').removeClass('col-xs-12').addClass('col-xs-12 col-sm-6 col-md-6 col-lg-3');
		$('#products .product_list > article .thumbnail-container').removeClass('col-xs-4 col-xs-5 col-md-3');
		$('#products .product_list > article .product-description').removeClass('center-block col-xs-4 col-xs-7 col-md-9');


		$('.display').find('li#grid').addClass('selected');
		$('.display').find('li#list').removeAttr('class');
		$.totalStorage('display', 'grid');
	}
}

// ---------------- Fixed header responsive ----------------------
function responsivecolumn(){

	if ($(document).width() <= 991)
	{
		$('.container #columns_inner #left-column').appendTo('.container #columns_inner #content-wrapper');
	}
	else if($(document).width() >= 992)
	{
		$('.container #columns_inner #left-column').prependTo('.container #columns_inner');
	}
}
$(document).ready(function(){responsivecolumn();});
$(window).resize(function(){responsivecolumn();});

// JS for calling loadMore
$(document).ready(function () {

	"use strict";
	var size_li_feat = $("#index #featured-products .featured_grid article.product_item").size();
	var size_li_new = $("#index .newproducts .newproduct_grid article.product_item").size();
	var size_li_bestseller = $("#index .bestseller_grid article.product_item").size();
	var size_li_special = $("#index .special-products #special-grid article.product_item").size();


	var x= 7;
	var y= 7;
	var b= 7;
	var s= 2;

	$('#index #featured-products .featured_grid article.product_item:lt('+x+')').fadeIn('slow');
	$('#index .newproducts .newproduct_grid article.product_item:lt('+y+')').fadeIn('slow');
	$('#index .bestseller_grid article.product_item:lt('+b+')').fadeIn('slow');
	$('#index .special-products #special-grid article.product_item:lt('+s+')').fadeIn('slow');


	$('.featured_grid .gridcount').click(function () {
		if(x==size_li_feat){
			$('.featured_grid .gridcount').hide();
			$('.featured_grid .cp-message').show();
		}else{
			x= (x+7 <= size_li_feat) ? x+7 : size_li_feat;
			$('#index #featured-products .featured_grid article.product_item:lt('+x+')').fadeIn(1000);
		}
	});

	$('.newproduct_grid .gridcount').click(function () {
		if(y==size_li_new){
			$('.newproduct_grid .gridcount').hide();
			$('.newproduct_grid .cp-message').show();
		}else{
			y= (y+5 <= size_li_new) ? y+5 : size_li_new;
			$('#index .newproducts .newproduct_grid article.product_item:lt('+y+')').fadeIn('slow');
		}
	});

	$('.bestseller_grid .gridcount').click(function () {
		if(b==size_li_bestseller){
			$('.bestseller_grid .gridcount').hide();
			$('.bestseller_grid .cp-message').show();
		}else{
			b= (b+7 <= size_li_bestseller) ? b+7 : size_li_bestseller;
			$('#index .bestseller_grid article.product_item:lt('+b+')').fadeIn(1000);
		}
	});

	$('#special-grid .gridcount').click(function () {
		if(s==size_li_special){

			$('#special-grid .gridcount').hide();
			$('#special-grid .cp-message').show();
		}else{
			s= (s+2 <= size_li_special) ? s+2 : size_li_special;
			$('#index .special-products #special-grid article.product_item:lt('+s+')').fadeIn('slow');
		}
	});
});

/*======  Add Item in Menu ==== */
$('#left-column ul#top-menu > li').addClass('main');
var ee1 = 1;
$('#left-column ul#top-menu li.main').each( function() {
	$(this).addClass( 'menu-'+ ee1 );
	ee1 = ee1 + 1;
});

function blogtoggle(){

	if ($(window).width() <= 991){
		$('.blog-home .h3.title').click(function(event) {
			if ($(this).hasClass('active'))
				$(this).removeClass('active');
			else
				$(this).addClass('active');
			$(this).toggleClass('active');
			$('.blog-home .h3.title').toggleClass('active');
			event.stopImmediatePropagation();
			$('.blog-home .homeblog-inner').slideToggle("slow");
		});
	}

}

jQuery(document).ready(function() {blogtoggle();});
$(window).resize(function(){blogtoggle();});

$(function () {
	var activeIndex = $('.active-tab').index(),
		$contentlis = $('.tabs-content li'),
		$tabslis = $('.product_info_tab .tabs li');

	// Show content of active tab on loads
	$contentlis.eq(activeIndex).show();

	$('.product_info_tab .tabs').on('click', 'li', function (e) {
		var $current = $(e.currentTarget),
			index = $current.index();
		$tabslis.removeClass('active-tab');
		$current.addClass('active-tab');
		$contentlis.hide().eq(index).show();
	});
});


$(document).ready(function(){
	var vid = document.getElementById("tmvideo");
	if( vid != null){
		vid.autoplay = true;
		vid.loop = true;
		vid.muted = true;
		vid.load();
	}else{
		vid=null;
	}

	$('#mute-toggle').on('click', function(ev) {
		$('#mute-toggle').css('display','none');
		$('#volume-toggle').css('display','block');
		vid.muted = false;
	});

	$('#volume-toggle').on('click', function(ev) {
		$('#volume-toggle').css('display','none');
		$('#mute-toggle').css('display','block');
		vid.muted = true;
	});
});

function searchtoggle() {
	if ($(window).width() >= 0) {
		$('#header .search_button').click(function (event) {
			$(this).toggleClass('active');
			$('#header #search_widget').toggleClass('active');
			event.stopImmediatePropagation();
			$("#header .searchtoggle").slideToggle("fast");
			$('#header .search-widget form input[type="text"]').focus();
		});
		$("#header .searchtoggle").on("click", function (event) {
			event.stopImmediatePropagation();
		});
	}
	else {
		$('#header .search_button,#header .searchtoggle').unbind();
		$('#search_widget').unbind();
		$("#header .searchtoggle").show();
	}
}
$(window).resize(function () { searchtoggle(); });
$(window).ready(function () { searchtoggle(); });
